import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>Ironman 70.3 Indian Wells Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="Ironman 70.3 Indian Wells Race Report" />
        <meta
          property="og:title"
          content="Ironman 70.3 Indian Wells Race Report"
        />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/703-indian-wells-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/703-indian-wells-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/703-indian-wells-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            Ironman 70.3 Indian Wells Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">December 3, 2023</p>
            <p className="lead mb-0">Indian Wells, California</p>
          </div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            A couple of weeks before the race, the shit hit the fan with
            Tri-Bike Transport basically going insolvent overnight. I had
            planned to use them to get my bike from Tennessee to California.
          </p>

          <p>
            I needed a new travel plan. Since we were flying from Nashville, and
            I don’t own a bike case, there weren’t a lot of attractive
            last-minute options. What we ended up doing sounds a bit crazy.
          </p>

          <p>
            Arkadelphia, Arkansas is precisely halfway between Nashville and
            Austin. Katy and I drove seven hours west, where we met Tiffany and
            Caitlin for dinner. Then, they took my bike back home to Austin
            where it eventually made the final stretch to California with them.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/arkadelphia.jpg"
                alt=""
              />
            </figure>
            <figcaption className="figure-caption">
              A not-at-all-staged photo of the bike handover in downtown
              Arkadelphia
            </figcaption>
          </div>

          <p>
            I’m flying out to Austin for work at the end of January. Between now
            and then, a bike case will be delivered to the Saunders’ home. When
            I fly back to Nashville, my bike will accompany me on the flight.
            Like I said, crazy.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Week</h1>
          <p>
            One of my great fears is that I’ll get sick during race week. It’s
            so front of mind that my brain will often play tricks on me. I’ll
            actually feel like I’m getting a sore throat or a headache when I’m
            not. It’s a terrifying thought to put in all the work to complete a
            training plan only to have it undone by getting sick at the worst
            possible time.
          </p>

          <p>
            For Indian Wells, that terrifying thought became reality. I had a
            nasty head cold the entire week leading into the race. The worst
            days were the Monday and Tuesday before our flight to California on
            Wednesday.
          </p>

          <p>
            I told myself that as long as I never tested positive for COVID, I
            was racing. So when I tickled my brain on Wednesday morning and only
            saw one line on the test, we were off to the airport!
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/covid-test.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            Even before I got sick, I was already worried about the cold. The
            water temperature would be in the upper 50s. The air temperature at
            the start of the race would be in the low to mid-40s. There was only
            one sensible thing to do.
          </p>

          <p>
            I put on my wetsuit and jumped into the pool at the Saunders’
            Airbnb.
          </p>

          <div className="two-image">
            <img
              src="/images/703-indian-wells-race-report/cannonball1.jpg"
              alt=""
            />
            <img
              src="/images/703-indian-wells-race-report/cannonball2.jpg"
              alt=""
            />
          </div>

          <p>
            I didn’t notice the dead mouse on the bottom of the pool until after
            I got out. 😆
          </p>

          <p>
            This cannonball was actually one of the most important things I did
            to prepare for the race. The water was freaking freezing! But I got
            the reassurance that I would eventually acclimate to the cold after
            a few minutes.
          </p>

          <p>
            The logistics for this race are rather complex. The bike course
            doesn’t start and end in the same place. We also had to sanitize our
            wetsuits before the swim. Not only that, it was a clean transition
            (meaning our gear bags and bikes would be stored in two different
            places.) It took several hours to drive around and get everything
            set where it needed to be.
          </p>

          <p>
            While complicated, it was equally picturesque with these giant
            mountains (at least by my definition as a Nashville resident) and
            lines of palm trees overlooking us at both transitions.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/t1-dropoff.jpg"
                alt=""
              />
            </figure>
          </div>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/t2-dropoff.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            With everything dropped off, there was just one final thing to tend
            to: shaving my legs. Whether it has any actual impact, I can’t say
            for certain. But I played baseball for many years. If something
            works, you don’t toy with the universe and ask why. You just keep
            doing it.
          </p>

          <p>I’ve never had a bad race with shaved legs.</p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/leg-hair.jpg"
                alt=""
              />
            </figure>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            It’s a bit of a dated reference, but one of the more famous moments
            in NBA history was Game 5 of the 1997 NBA Finals. Michael Jordan had
            flu-like symptoms, but he played through it, scoring 38 points,
            including a game-winning 3-pointer with under a minute to go. 25+
            years later, there’s an entire episode in The Last Dance about
            what’s known as The MJ Flu Game.
          </p>

          <p>
            As race week led to race day, it became clear I wouldn’t be fully
            healthy. I had no idea what to expect from myself, but I knew I’d
            forever refer to this day as my MJ Flu Race.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/sunrise.jpg"
                alt=""
              />
            </figure>
          </div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>

          <p>
            <strong>Time:</strong> 43:10
            <br />
            <strong>Distance:</strong> 1.2 miles
            <br />
            <strong>Pace:</strong> 2:01/100 yd
            <br />
            <strong>AG:</strong> 126/221
            <br />
            <strong>Men:</strong> 942/1600
            <br />
            <strong>Overall:</strong> 1219/2331
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Clear skies and 42°F
            <br />
            <strong>Water Temperature:</strong> 58°F
            <br />
            <strong>Current:</strong> None
            <br />
            <strong>Wetsuit:</strong> Yes
          </p>

          <p>
            I was nervous. I felt uneasy about my overall stamina. I knew I
            wasn’t 100 percent, but where was I? Rather than try to guess, I
            decided to race like I wasn’t sick. If I flame out along the way, so
            be it. Michael Jordan didn’t hold anything back in Game 5. If this
            is my MJ Flu Race, I need to act like it!
          </p>

          <div className="two-image">
            <img
              src="/images/703-indian-wells-race-report/wetsuit1.jpg"
              alt=""
            />
            <img
              src="/images/703-indian-wells-race-report/wetsuit2.jpg"
              alt=""
            />
          </div>

          <p>
            This wasn’t a pleasant swim. I felt I had seeded myself correctly,
            but the lake was very crowded. Maybe they were starting too many
            athletes at the same time. But I constantly had people bumping into
            me or kicking me. The most frustrating aspect was that people seemed
            to have trouble sighting (or they just didn’t know how to do it.)
            People would stop entirely, look around, find the next buoy, and
            then resume swimming. With the course already crowded, it felt like
            stop & go traffic.
          </p>

          <p>
            I’m a bit disappointed with my time. I know the situation was hardly
            ideal, but I like to think I’m capable of always doing the 70.3 swim
            in under 40 minutes. However, I’m happy I got through my weakest
            discipline with only a couple of bruises from being kicked. I’ll
            take it!
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>

          <p>
            Given the temperatures, Caitlin had mentioned she planned to take
            her time in T1 so that she could dry off as best she could. I’m glad
            she put that thought in my head. On a warm day, it’s great to hop on
            the bike wet and let the breeze cool you off. This was not a day for
            that.
          </p>

          <p>
            I’d put some tube socks with the ends cut off into my transition
            bag. After thoroughly drying off, I slid a sock onto each arm. I
            knew it would warm up as the day went on, but I wanted a little
            extra warmth to start the ride.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>

          <p>
            <strong>Time:</strong> 2:34:42
            <br />
            <strong>Distance:</strong> 56 miles
            <br />
            <strong>Pace:</strong> 21.9 mph
            <br />
            <strong>AG:</strong> 75/221
            <br />
            <strong>Men:</strong> 526/1600
            <br />
            <strong>Overall:</strong> 620/2331
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Sunny and warming into the 60s
            <br />
            <strong>Road Conditions:</strong> Mostly fine, but a few rough
            patches
            <br />
            <strong>Terrain:</strong> Mostly flat
          </p>

          <p>
            I was excited about this bike ride. This would be the flattest 70.3
            course I’ve ever been on. There wouldn’t be a lot of shifting. I’d
            just be able to get into a groove and let it rip.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/bike-palm-trees.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            My tube socks turned arm sleeves were perfect. I had expected to rip
            them off and toss them in the trash at one of the aid stations along
            the course, but I actually rode in them all 56 miles. It wasn’t
            until I started the run that I felt warm.
          </p>

          <p>
            Overall, this was a pretty uneventful ride. The pavement was a bit
            bumpy out in the desert, but nothing that wasn’t manageable.
          </p>

          <p>
            Around mile 40, we got to ride a loop on The Thermal Club’s private
            race track. Not only was the pavement suddenly <em>pristine</em>,
            but taking some of those corners was so much fun.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/race-track.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            As I left the race track, I started feeling the fatigue from being
            sick. Thankfully, it wasn’t like I hit a wall and suddenly had no
            energy. However, I noticed that my watts were slowly decreasing
            despite feeling like I was putting in more effort now than earlier
            in the race.
          </p>

          <p>
            When I rolled into T2, I felt rough. I had started to get some side
            stitches over the final few miles. Looking at my metrics, my speed
            didn’t drop all that much, but it was a big effort - both physically
            and mentally - to get through the last few miles. I was ready to
            finish the bike and move on to my best discipline.
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>

          <p>
            When we dropped off our run bags before the race, Florida State
            hadn’t yet played in the ACC Championship game.
          </p>

          <p>
            Since I wouldn’t see my run bag again until during the race, I put
            two different hats in. I usually wear a Braves hat during the run.
            But today, I had also tossed in a Florida State hat. If we ended up
            winning the ACC, I would don the Noles.
          </p>

          <div className="two-image">
            <img
              src="/images/703-indian-wells-race-report/braves-noles.jpg"
              alt=""
            />
            <img
              src="/images/703-indian-wells-race-report/acc-champions.jpg"
              alt=""
            />
          </div>

          <p>I’ve never been happier not to wear a Braves hat.</p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>

          <p>
            <strong>Time:</strong> 1:47:33
            <br />
            <strong>Distance:</strong> 13.1 miles
            <br />
            <strong>Pace:</strong> 8:01 / mile
            <br />
            <strong>AG:</strong> 70/221
            <br />
            <strong>Men:</strong> 471/1600
            <br />
            <strong>Overall:</strong> 553/2331
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Sunny
            <br />
            <strong>Road Conditions:</strong> Mostly paved with a few grassy
            sections
            <br />
            <strong>Terrain:</strong> Rolling hills
          </p>

          <p>
            It’s so odd. Of the three disciplines, the run is the one I enjoy
            the least. But if you go by age group rankings, it’s the one I am
            best at.
          </p>

          <p>
            I was so relieved to be off the bike. However, I also felt far more
            fatigue than usual at this point in the day. As I set off on the
            run, I had to remind myself of a couple of things. Sure, I feel
            shitty, and I’m tired, but I’m just 13.1 miles away from wrapping up
            a truly incredible 2023 race season.
          </p>

          <p>
            I’m not sure how this happened, but according to Strava, this run is
            my second fastest half marathon <em>ever</em>, including standalone
            runs.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/703-indian-wells-race-report/run.jpg" alt="" />
            </figure>
          </div>

          <p>
            Much like on the bike, I felt like I was fading as the miles ticked
            by. The second lap was HARD. It felt like I was working so much
            harder on the final miles than the first few – but the metrics tell
            a different story. I basically ran the exact same pace over the
            entire 13.1 miles.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/run-metrics.png"
                alt=""
              />
            </figure>
          </div>

          <p>
            Those dramatic but short dips in pace are from walking for a few
            seconds at each aid station. Maybe someday I’ll learn how to drink,
            eat, and run simultaneously. Whenever I try to, I just end up
            covering myself in Gatorade.
          </p>

          <p>
            One of my big worries coming into the day would be that I’d have a
            coughing fit during the run. My nose can run all day long (and it
            did 🫠), but that’s something I can race through. Having trouble
            breathing is a different beast. But thankfully, that wasn’t an
            issue!
          </p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Post-Race</h1>

          <p>
            <strong>Overall Time:</strong> 5:18:03
            <br />
            <strong>AG:</strong> 70/221
            <br />
            <strong>Men:</strong> 471/1600
            <br />
            <strong>Overall:</strong> 553/2331
            <br />
          </p>

          <p>
            I’ll never have another race season like 2023. Somehow, I have set a
            PR for that respective distance in <em>every single triathlon</em>{" "}
            throughout the entire year. That streak includes this race. Despite
            being sick, I did this 70.3 about two minutes faster than at Worlds.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/finish-line.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            When I finished, I had a bit of a coughing fit for a few minutes. It
            was kind of gross, but I was just relieved this happened after I
            finished! Lesson learned: getting sick the week leading into a race
            is not a good idea. Let’s never do this again!
          </p>

          <p>
            Before the race, while pumping my tires, I came up with a great idea
            for a post-race medal photo. Surely, the CFP committee would put
            undefeated 13-0 conference champion Florida State into the playoff
            at the Rose Bowl. After the race, I would buy some roses and
            surround the medal with them.
          </p>

          <p>WELP.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <h1>Final Thoughts</h1>
          <p>
            At the end of the 2022 season, my fastest 70.3 time was 6:30:30.
            Coming into 2023, I hoped to find a way to finish one of these in
            under six hours. That meant taking a half hour off my best 70.3
            time. Cutting 30 minutes from your race is not easy! If I found a
            way to hit 5:59:59 just once, I would have considered the season a
            smashing success.
          </p>

          <p>I was never close to 5:59:59.</p>

          <p>
            Chattanooga: 5:24:09
            <br />
            Worlds/Finland: 5:19:49
            <br />
            Indian Wells: 5:18:03
          </p>

          <p>
            Year over year, I’m 72 minutes faster. I got to race at the 70.3
            World Championship. Next season, I’ll have All World Athlete status.
            It’s all surreal.
          </p>

          <p>
            But when I think back on 2023, while I’m proud of my race times,
            they are not among my fondest memories I’ll reminisce about forever.
          </p>

          <p>
            It’ll be that I got to swim in the pool under Lahti’s ski jumps.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/lahti-ski-jumps.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>Or shaving my legs for the first time on Instagram Live.</p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/leg-shaving.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>Or the award ceremony in Chattanooga.</p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/chatt-award-ceremony.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            Or having a true Jim Valvano day by doing what he said: having my
            emotions moved to tears.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/703-indian-wells-race-report/tears.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            What’s the one thing all of these moments have in common? None of
            them actually happened during a race.
          </p>

          <p>
            I love this sport. Four years in, I think I’ve gotten moderately
            talented at it. But what keeps me coming back isn’t the swimming,
            biking, and running – it’s everything else.
          </p>

          <p>I don’t know what I’m doing in 2024 yet - but I can’t wait.</p>

          <p>
            (One more thing: I have to give a final shout out to Katy. She not
            only tolerates this weird hobby, but supports everything from the
            training plan to the stinky hamper to the husband who occasionally
            has shaved legs. Even after all that, she gets up early with us on
            race day. She’s the best.)
          </p>
          <div className="clearfix"></div>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
